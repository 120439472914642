import React from 'react';
import PropTypes from 'prop-types';

import MultiProductHeader from '../../components/MultiProductHeader/Header';
import MultiProductFooter from '../../patterns/MultiProductFooter/MultiProductFooter';
import Menu from '../../components/Menu/Menu';
import RegistrationDrawer from '../../patterns/RegistrationDrawer/RegistrationDrawer';
import MainContent from '../../components/MainContent/MainContent';
import HeaderCTA from '../../components/HeaderCTA/HeaderCTA';
import WebsiteSEO from '../../components/SEO/websiteSEO';
import { getEnableCucuta } from '../../utils/environmentUtils';
import CucMenuHeader from '../../components/CucMenuHeader/CucMenuHeader';
import HiddeableHeaderCTA from '../../components/HiddeableHeaderCTA/HiddeableHeaderCTA';

export const DEFAULT_ROUTE_KEY_META_DATA = 'HOME';

export const cucutaCTA = (
  <HiddeableHeaderCTA
    hiddenHeight={{
      xs: 647,
      sm: 1135,
      md: 723,
      lg: 723,
      xl: 723,
    }}
    btnLabel="MULTI_PRODUCT_HOME.HERO.FORM.BUTTON_LABEL"
    styleVariant="primary"
  />
);

export const headerLayouts = {
  NO_APPLICATION: {
    showHeaderCTA: false,
  },
  NO_APPLICATION_NO_MENU: {
    showHeaderCTA: false,
    showHeaderMenu: false,
    showHeaderTopMenu: false,
  },
  ALL: {
    showHeaderCTA: true,
    showHeaderMenu: true,
    showHeaderTopMenu: true,
  },
};

export const footerLayouts = {
  NONE: {
    showFooter: false,
  },
  ALL: {
    showFooter: true,
  },
};

const hocDefaultOptions = {
  routeKey: 'HOME',
  header: {
    layout: headerLayouts.ALL,
    cta: <HeaderCTA />,
  },
  footer: footerLayouts.ALL,
};

const HeaderComponent = ({
  hocOptions,
}) => {
  const { components } = hocOptions;
  const CustomHeader = components?.header;

  if (CustomHeader) {
    return <CustomHeader />;
  }

  const { header: hocHeaderOptions } = hocOptions;
  const header = { ...hocDefaultOptions.header, ...hocHeaderOptions };

  const {
    cta, layout, nuLogoColor, nuLogoHref,
  } = header;
  const { showHeaderCTA, showHeaderMenu, showHeaderTopMenu } = layout;

  if (getEnableCucuta === 'true') {
    return (
      <CucMenuHeader
        showCTA={showHeaderCTA}
        CTA={cucutaCTA}
        showMenuBtn={showHeaderMenu}
        showHeaderTopMenu={showHeaderTopMenu}
        nuLogoHref={nuLogoHref}
      />
    );
  }

  return (
    <MultiProductHeader
      showCTA={showHeaderCTA}
      CTA={cta}
      showMenuBtn={showHeaderMenu}
      showHeaderTopMenu={showHeaderTopMenu}
      nuLogoColor={nuLogoColor}
      nuLogoHref={nuLogoHref}
    />
  );
};

HeaderComponent.propTypes = {
  hocOptions: PropTypes.shape({
    components: PropTypes.shape({
      header: PropTypes.shape({
        cta: PropTypes.element,
        nuLogoHref: PropTypes.string,
      }),
    }),
    header: PropTypes.shape({
      cta: PropTypes.element,
      nuLogoHref: PropTypes.string,
    }),
  }).isRequired,
};

const FooterComponent = ({ hocOptions }) => {
  const { components } = hocOptions;
  const CustomFooter = components?.footer;

  if (CustomFooter) {
    return <CustomFooter hocOptions={hocOptions} />;
  }

  const { footer: hocFooterOptions } = hocOptions;
  const footer = { ...hocDefaultOptions.footer, ...hocFooterOptions };
  const { showFooter } = footer;
  return (showFooter && <MultiProductFooter />);
};

FooterComponent.propTypes = {
  hocOptions: PropTypes.shape({
    components: PropTypes.shape({
      footer: PropTypes.func,
    }),
    footer: PropTypes.shape({
      showFooter: PropTypes.bool,
    }),
  }).isRequired,
};

const websitePage = (WrappedComponent, hocOptions) => {
  const { routeKey, openGraphResource } = { ...hocDefaultOptions, ...hocOptions };

  const WebsitePageComponent = props => (
    <>
      <WebsiteSEO routeKey={routeKey} openGraphResource={openGraphResource} />
      <HeaderComponent hocOptions={hocOptions} />

      <Menu />

      <RegistrationDrawer />

      <MainContent {...hocOptions}>
        <WrappedComponent {...props} />
      </MainContent>
      <FooterComponent hocOptions={hocOptions} />
    </>
  );

  WebsitePageComponent.getInitialProps = async cyx => {
    let pageProps = {};

    if (WrappedComponent.getInitialProps) {
      pageProps = await WrappedComponent.getInitialProps(cyx);
    }
    return {
      ...pageProps,
    };
  };

  return WebsitePageComponent;
};

export default websitePage;
